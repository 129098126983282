@import url("https://fonts.googleapis.com/css2?family=Lato&family=Raleway:wght@600;700;900&display=swap");

body {
	scroll-behavior: smooth !important;
}
.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.al-left {
	text-align: left;
}

.text-blue {
	color: rgb(10, 132, 254);
}

.lh-0 {
	line-height: 0;
}

.logo-text {
	margin-top: 0;
	font-weight: bolder;
}

.m-0 {
	margin-bottom: 15px;
}

.lable-text {
	font-weight: 400;
	font-size: 34px !important;
}

.login-btn {
	height: 60px !important;
	width: 300px !important;
	font-size: 1.2rem !important;
	color: rgb(69, 79, 99) !important;
	border-radius: 12px !important;
	padding: 10px 20px 10px 20px !important;
	border: none;
	outline: none;
}
label {
	font-weight: 600;
	font-size: 0.9rem !important;
}
label span {
	font-size: 1.3rem !important;
}
.wide {
	width: 20%;
}
.cursor-pointer {
	cursor: pointer;
}
.font-size-small {
	font-size: 1rem;
	margin-top: 0.7rem;
}
.font-size-semi {
	font-size: 1rem;
	color: black !important;
	margin-top: 1.25rem !important;
}
.font-weight-bold {
	font-weight: bold;
}

.font-size-larg {
	font-size: 1rem !important;
}
.float-right {
	float: right;
}
.font-red {
	color: red;
}
.form-control::placeholder {
	color: rgb(97, 97, 97) !important;
}
.sweet-alert {
	width: 300px !important;
}
.sweet-alert h1,
h2,
h3 {
	font-size: 1rem !important;
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body,
html {
	height: 100%;
	font-family: Raleway, sans-serif;
}

/*---------------------------------------------*/
a {
	font-family: Raleway;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

p {
	font-family: Raleway;
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul,
li {
	margin: 0px;
	list-style-type: none;
}

/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
	outline: none;
	border: none;
}

textarea:focus,
input:focus {
	border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
	color: transparent;
}
input:focus:-moz-placeholder {
	color: transparent;
}
input:focus::-moz-placeholder {
	color: transparent;
}
input:focus:-ms-input-placeholder {
	color: transparent;
}

textarea:focus::-webkit-input-placeholder {
	color: transparent;
}
textarea:focus:-moz-placeholder {
	color: transparent;
}
textarea:focus::-moz-placeholder {
	color: transparent;
}
textarea:focus:-ms-input-placeholder {
	color: transparent;
}

input::-webkit-input-placeholder {
	color: #adadad;
}
input:-moz-placeholder {
	color: #adadad;
}
input::-moz-placeholder {
	color: #adadad;
}
input:-ms-input-placeholder {
	color: #adadad;
}

textarea::-webkit-input-placeholder {
	color: #adadad;
}
textarea:-moz-placeholder {
	color: #adadad;
}
textarea::-moz-placeholder {
	color: #adadad;
}
textarea:-ms-input-placeholder {
	color: #adadad;
}

label {
	display: block;
	margin: 0;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ utility ]*/

/*//////////////////////////////////////////////////////////////////
[ Contact ]*/

.container-contact100 {
	width: 100%;
	min-height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background: #677cd8;
	background: -webkit-linear-gradient(45deg, #7f9cf2, #3917d4);
	background: -o-linear-gradient(45deg, #7f9cf2, #3917d4);
	background: -moz-linear-gradient(45deg, #7f9cf2, #3917d4);
	background: linear-gradient(45deg, #7f9cf2, #3917d4);
}

.wrap-contact100 {
	width: 790px;
	background: #fff;
	border-radius: 2px;
	padding: 55px 95px 68px 95px;
}

/*==================================================================
[ Form ]*/

.contact100-form {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.contact100-form-title {
	width: 100%;
	display: block;
	font-family: Raleway;
	font-weight: 900;
	font-size: 30px;
	color: #333333;
	line-height: 1.2;
	text-align: center;
	padding-bottom: 45px;
}

/*------------------------------------------------------------------
[ Input ]*/

.wrap-input100 {
	width: 100%;
	position: relative;
	border: 1px solid #e6e6e6;
	border-radius: 2px;
	margin-bottom: 34px;
}

.rs1.wrap-input100 {
	width: calc((100% - 40px) / 2);
}

.label-input100 {
	font-family: Raleway;
	font-size: 13px;
	color: #555555;
	line-height: 1.5;
	text-transform: uppercase;
	width: 100%;

	padding-bottom: 11px;
}

.input100 {
	display: block;
	width: 100%;
	background: transparent;
	font-family: Raleway;
	font-size: 18px;
	color: #333333;
	line-height: 1.2;
	padding: 0 25px;
}

input.input100 {
	height: 55px;
}

textarea.input100 {
	min-height: 162px;
	padding-top: 19px;
	padding-bottom: 15px;
}

/*---------------------------------------------*/

.focus-input100 {
	position: absolute;
	display: block;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
	top: -1px;
	left: -1px;
	pointer-events: none;
	border: 1px solid;
	border-color: #d867c6;
	border-color: -webkit-linear-gradient(45deg, #c77ff2, #e8519e);
	border-color: -o-linear-gradient(45deg, #c77ff2, #e8519e);
	border-color: -moz-linear-gradient(45deg, #c77ff2, #e8519e);
	border-color: linear-gradient(45deg, #c77ff2, #e8519e);

	visibility: hidden;
	opacity: 0;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;

	-webkit-transform: scaleX(1.1) scaleY(1.3);
	-moz-transform: scaleX(1.1) scaleY(1.3);
	-ms-transform: scaleX(1.1) scaleY(1.3);
	-o-transform: scaleX(1.1) scaleY(1.3);
	transform: scaleX(1.1) scaleY(1.3);
}

.input100:focus {
	outline: 1px solid rgb(12, 81, 255) !important;
	border-radius: 5px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-contact100-form-btn {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: -4px;
}

.contact100-form-btn {
	font-family: Raleway;
	font-weight: 900;
	font-size: 16px;
	color: #fff;
	line-height: 1.2;

	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	min-width: 150px;
	height: 55px;
	border-radius: 27px;
	background: #333333;
	/* background: -webkit-linear-gradient(45deg, #3917d4, #3917d4);
  background: -o-linear-gradient(45deg, #3917d4, #3917d4);
  background: -moz-linear-gradient(45deg, #3917d4, #3917d4);
  background: linear-gradient(45deg, #3917d4, #3917d4); */
	position: relative;
	z-index: 1;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.contact100-form-btn::before {
	content: "";
	display: block;
	position: absolute;
	z-index: -1;
	border-radius: 27px;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #4927e1;
	opacity: 1;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.contact100-form-btn:hover:before {
	opacity: 0;
}

.contact100-form-btn i {
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}
.contact100-form-btn:hover i {
	-webkit-transform: translateX(10px);
	-moz-transform: translateX(10px);
	-ms-transform: translateX(10px);
	-o-transform: translateX(10px);
	transform: translateX(10px);
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 768px) {
	.wrap-contact100 {
		padding: 55px 45px 68px 45px;
	}
}

@media (max-width: 576px) {
	.wrap-contact100 {
		padding: 55px 15px 68px 15px;
	}

	.rs1.wrap-input100 {
		width: 100%;
	}
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
	position: relative;
}

.alert-validate .focus-input100 {
	box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
	-moz-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
	-webkit-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
	-o-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
	-ms-box-shadow: 0 5px 20px 0px rgba(250, 66, 81, 0.1);
}

.alert-validate::before {
	content: "";
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	position: absolute;
	width: calc(100% + 2px);
	height: calc(100% + 2px);
	background-color: transparent;
	border: 1px solid #fa4251;
	border-radius: 2px;
	top: -1px;
	left: -1px;
	pointer-events: none;
}

.btn-hide-validate {
	font-family: Material-Design-Iconic-Font;
	font-size: 18px;
	color: #fa4251;
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
	top: 0px;
	right: -28px;
}

.rs1-alert-validate.alert-validate::before {
	background-color: #fff;
}

.true-validate::after {
	content: "\f26b";
	font-family: Material-Design-Iconic-Font;
	font-size: 18px;
	color: #00ad5f;

	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	height: 100%;
	top: 0px;
	right: -28px;
}

/*---------------------------------------------*/
@media (max-width: 576px) {
	.btn-hide-validate {
		right: 10px;
	}
	.true-validate::after {
		right: 10px;
	}
}

.css-yk16xz-control {
	border: none !important;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	padding-left: 10px !important;
}

.css-1pahdxg-control {
	border: none !important;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
}
/* .css-6j8wv5-Input{
  grid-template-columns: unset !important;
} */
#react-select-3-placeholder {
	margin-left: 10px !important;
	color: #aeaeae !important;
	font-family: Lato !important;
	font-size: 1.1rem !important;
}
#react-select-2-placeholder {
	margin-left: 10px !important;
	color: #aeaeae !important;
	font-family: Lato !important;
}

.css-1s2u09g-control {
	border: none !important;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
}
.bg-grey {
	background-color: rgb(241, 241, 237) !important;
}

.bg-grad {
	background: #e3e3e3;
	/* background: -webkit-linear-gradient(45deg, #3917d4, #3917d4) !important; */
}
input {
	background-color: white !important;
	font-family: Lato !important;
}
textarea {
	background-color: white !important;
}
.wrap-input100 {
	margin-bottom: 20px !important;
}
.pills {
	display: inline-block;
	border: 0.5px solid rgb(153, 153, 153);
	border-radius: 50px;
	padding: 5px 15px 5px 15px;
	color: rgb(74, 74, 74);
	margin: 5px;
	cursor: pointer;
	font-size: 0.8rem;
}
.pills:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; /* background-color: white; */
	border: 0.5px solid transparent;
	transition: all 0.3s ease-in-out;
}

.border-none {
	border: none;
}

textarea {
	font-family: Lato !important;
}

.error {
	float: right;
	color: red !important;
	display: block !important;
}

.wrap-input100 {
	border: none !important;
}
#react-select-3-input {
	margin-left: 3px !important;
}
#react-select-2-input {
	margin-left: 3px !important;
}

.css-yk16xz-control + div {
	z-index: 999 !important;
	font-family: Lato !important;
}
.css-1wa3eu0-placeholder {
	margin-left: 10px !important;
	font-size: 1.1rem !important;
	color: #aeaeae !important;
	font-family: Lato !important;
}

.css-1pahdxg-control + div {
	z-index: 999 !important;
	font-family: Lato !important;
}

.form-check-input:checked {
	background-color: #0d6efd !important;
	border-color: #0d6efd;
}

.css-qc6sy-singleValue {
	margin-left: 10px !important;
	font-family: Lato !important;
}
.css-1uccc91-singleValue {
	margin-left: 10px !important;
	font-family: Lato !important;
}
#address-ip {
	margin-bottom: 0px !important;
}

.loading-add {
	position: absolute;
	right: 15px;
	top: 0;
	height: 55px;
	display: flex;
	z-index: 999;
	cursor: pointer;
}
.autocomplete-dropdown-container {
	position: absolute !important;
	top: 57.5px;
	z-index: 999;
	width: 100%;
	padding-top: 10px !important;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
		rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.suggestion-item {
	padding: 4px;
	margin-left: 0px !important;
}
.suggestion-item--active {
	padding: 4px;
	background-color: #deebff !important;
}
.item-margins {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 20px;
	margin-right: 20px;
}
.cross-button {
	position: absolute;
	right: 15px;
	top: -35px;
}
#searchadd {
	padding-right: 34px !important;
}
.ql-container {
	display: flex;
	flex-direction: column;
	padding: 0px;
	/* flex: 1; */
	/* background-color: #0004ff; */
	/* height: 5vh; */
	overflow: hidden;
	border: none;
	border-color: transparent;
}

.ql-editor.ql-blank::before {
	font-family: Lato !important;
	/* font-size: 18px; */
	color: #b0b0b0 !important;
	font-style: normal !important;
	padding-left: 9px !important;
}
.ql-editor p {
	font-family: Lato !important;
	font-size: 18px;
	font-style: normal !important;
	padding-left: 9px !important;
}
.ql-editor h1 {
	font-family: Lato !important;
	font-style: normal !important;
	padding-left: 9px !important;
}
.email-valid-inactive div {
	border-color: #adadad !important;
	&:hover {
		background-color: #ececec !important;
		cursor: not-allowed !important;
	}
}
.email-valid-inactive p {
	color: #adadad !important;
}
.email-valid {
	padding-right: 5px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
}
.email-valid-text {
	border: none !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-width: fit-content;
	&:hover {
		background-color: none !important;
		cursor: default !important;
	}
}
.email-valid-text p {
	min-width: fit-content;
	color: #00ad5f !important;
}
.email-valid-text img {
	height: 18px;
	width: 18px;
	margin-left: 5px;
}
.email-valid-text-invalid p {
	color: red !important;
}
.email-valid-text-invalid img {
	min-height: 18px;
	min-width: 18px;
	border: 1.5px solid red;
	border-radius: 100%;
	padding: 2px;
}
.ql-snow .ql-picker.ql-size .ql-picker-list {
	max-height: 150px; /* Adjust the maximum height as needed */
	overflow-y: auto !important; /* Enable vertical scrolling */
}

.version-text {
	position: absolute;
	top: 55%;
	left: 19%;
	font-size: 9px;
	color: "black";
}
